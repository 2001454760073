@font-face {
	font-family: Poppins-Regular;
	src: url(../ttf/Poppins-Regular.ttf) format("truetype");
}

:root {
	--body-background: #fff;
	--spinner-color: #00152a;
	--loader-background: #f2f2f2;
	--body-color: #212529;
	--bg-dark-custom: #272d34;
	--btn-notificaciones-color: #93969a;
	--sidebar-background: rgb(0, 21, 42);
	--sidebar-item-background: rgb(255, 153, 0);
	--sidebar-item-color: #000;
	--home-shortcut-title-background: #f59e0b;
	--home-shortcut-title-color: #000;
	--contenedor-cursos-background: #f1f1f1;
	--contenedor-rutas-background: #f1f1f1;
	--contenedor-lecciones-mos: #fff;
	--orange-gc: #ff9900;
	--yellow-gc: #ffcc00;
	--green-success: #28a745;
	--card-download-plantilla-hover: #eee;
	--info-cuenta-avances-background: #f2f2f2;
	--border-modal-sweetalert: none;
	--overlay-panel-background: #fff;
	--overlay-panel-color: #000;
	--hover-overlay-panel-background: #f3f3f3;
	--hover-overlay-panel-color: #fff;
	
}

html[data-bs-theme="dark"] {
	--body-background: #121212;
	--spinner-color: #fff;
	--loader-background: var(--body-background);
	--body-color: #fff;
	--bg-dark-custom: #272d34;
	--btn-notificaciones-color: #93969a;
	--sidebar-background: rgb(0, 21, 42);
	--sidebar-item-background: rgb(255, 153, 0);
	--sidebar-item-color: #000;
	--home-shortcut-title-background: #f59e0b;
	--home-shortcut-title-color: #000;
	--contenedor-cursos-background: rgb(52 58 64);
	--contenedor-rutas-background: rgb(33 37 41);
	--contenedor-lecciones-mos: rgb(33 37 41);
	--orange-gc: #ff9900;
	--yellow-gc: #ffcc00;
	--green-success: #28a745;
	--card-download-plantilla-hover: rgb(34, 36, 38);
	--info-cuenta-avances-background: rgb(52 58 64);
	--border-modal-sweetalert: 1px solid #495057;
	--overlay-panel-background: #495057;
	--overlay-panel-color: #fff;
	--hover-overlay-panel-background: #ffcc00;
	--hover-overlay-panel-color: #000;
}





.p-overlaypanel {
	background-color: var(--overlay-panel-background)!important;	
}

.notificacion-usuario:hover {
	background-color: var(--hover-overlay-panel-background)!important;
	color: var(--hover-overlay-panel-color)!important;
}

.notificacion-usuario.leida {
	color: var(--overlay-panel-color);
}

.notificacion-usuario.no-leida {
	color: var(--overlay-panel-color);
}

div:where(.swal2-container) .swal2-html-container,
.react-joyride__tooltip,
.react-joyride__tooltip > div:nth-child(2) > div > button {
	color: var(--body-color)!important;
}

.react-joyride__tooltip,
.swiper-slide {
	background-color: var(--contenedor-cursos-background)!important;
}

.card-download-plantilla:hover,
.admin-panel-item:hover {
    background-color: var(--card-download-plantilla-hover)!important;
}

.card-predefinidas,
.table>:not(caption)>*>* {
    background-color: var(--body-background)!important;
}


.info-cuenta-avances {
	background-color: var(--info-cuenta-avances-background)!important;
}

.seccion-actual {
	color: #000;
}

.btn-listado-cursos {
	background-color: #f8f9fa;
}

.row-foro {
	margin-bottom: 1rem;
	border-radius: 10px;
	border: var(--border-modal-sweetalert);
}

.loader-container {
	position: absolute;
	z-index: 200;
	background-color: var(--loader-background);
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.home-shortcut-title {
	background: var(--home-shortcut-title-background);
	color: var(--home-shortcut-title-color);
	padding: 0.7rem;
	border-radius: 10px;
}

.menu-desplegable{
	min-width: 15em !important;
	text-align: center !important;
}

body {
	font-size: 1rem;
	font-family: Poppins-Regular;
	overflow: hidden !important;

	/* Colores */
	background-color: var(--body-background);
	color: var(--body-color);
}

.dropdown-menu {
	z-index: 30000 !important;
}

.pointer {
	cursor: pointer !important;
}

.text-black {
	color: #000 !important;
}

.none-decoration-text {
	text-decoration: none !important;
}

.p-colorpicker-overlay-panel {
	z-index: 2000 !important;
}

@media (min-width: 2560px) {
	body {
		font-size: 2rem;
	}

	input,
	.navbar-buttons {
		height: 2.5vw !important;
	}

	.navbar-buttons {
		width: 2.5vw !important;
		font-size: 2rem;
	}

	.avatar {
		width: 3rem;
		height: 3rem;
	}
}

.test .nav-item {
	color: grey;
}

.nav-tabs .nav-link.active {
	background-color: var(--sidebar-item-background);
}

.p-accordion .p-accordion-header .p-accordion-header-link {
	text-decoration: none !important;
}

.lordicon-container {
	margin: 0 auto;
	width: 200px !important;
	height: 150px !important;
}

.pagination-lg .page-link {
	font-size: 0.8rem !important;
}

small {
	font-size: 65%;
}

.custom-anchor {
	font-size: 90%;
}

.css-jbefem .ps-menu-button {
	text-decoration: none !important;
}

.spinner {
	width: 56px;
	height: 56px;
	display: grid;
	border: 4.5px solid #0000;
	border-radius: 50%;
	border-color: var(--spinner-color) #0000;
	animation: spinner-e04l1k 1.4s infinite linear;
}

.spinner::before,
.spinner::after {
	content: "";
	grid-area: 1/1;
	margin: 2.2px;
	border: inherit;
	border-radius: 50%;
}

.spinner::before {
	border-color: #f59e0b #0000;
	animation: inherit;
	animation-duration: 0.7s;
	animation-direction: reverse;
}

.spinner::after {
	margin: 8.9px;
}

.css-19id26o {
	height: 100% !important;
	/* height: 90vh !important; */
}

.swal2-modal {
	border: var(--border-modal-sweetalert)!important;
}

@keyframes spinner-e04l1k {
	100% {
		transform: rotate(1turn);
	}
}

@media (min-width: 2000px) {
	h1 {
		font-size: 4.4rem !important;
	}

	h2 {
		font-size: 3.4rem !important;
	}

	h3 {
		font-size: 3rem !important;
	}

	h4 {
		font-size: 2.7rem !important;
	}

	h5 {
		font-size: 2.4rem !important;
	}

	h6 {
		font-size: 2rem !important;
	}

	small {
		font-size: 1.5rem;
	}

	b {
		font-size: 30px;
	}

	p {
		font-size: 30px;
	}

	select {
		font-size: 1.7rem !important;
	}

	label {
		font-size: 1.2rem !important;
	}

	input {
		font-size: 1.2rem !important;
	}

	button {
		font-size: 2.1rem !important;
	}

	textarea {
		font-size: 1.7rem !important;
	}
	.form-check-input {
		margin-top: -0.7rem;
		margin-left: -2.25rem;
		width: 2rem;
	}

	td {
		font-size: 1.7rem !important;
	}
	svg {
		font-size: 40px !important;
	}
	i {
		font-size: 2.1rem !important;
	}
	span {
		font-size: 1.7rem !important;
	}

	.alert {
		font-size: 1.7rem !important;
	}

	.p-button {
		font-size: 2.1rem !important;
	}

	.lordicon {
		width: 300px !important;
		height: 250px !important;
	}

	.lordicon-container {
		margin: 0 auto;
		width: 300px !important;
		height: 250px !important;
	}

	.p-inputtext {
		font-size: 1.7rem !important;
	}

	.select-form {
		font-size: 1.7rem !important;
	}
	.ellipsis {
		font-size: 2rem !important;
	}

	.hIWEKa {
		font-size: 26px !important;
		margin-bottom: 2rem !important;
	}

	.DSsoU {
		font-size: 30px !important;
		margin-bottom: 1rem !important;
	}

	.gKbhqU {
		font-size: 30px !important;
	}
	.fyrdjl {
		font-size: 2rem !important;
	}

	.avatar {
		width: 4rem !important;
		height: 4rem !important;
	}
	.titulo-dashboard {
		font-size: 3.5rem;
	}
	.separador-titulo {
		width: 120px;
	}

	.swal2-popup {
		width: 1500px !important;
	}

	.row-foro {
		height: 350px !important;
	}

	.pagination-lg .page-link {
		font-size: 3.25rem !important;
	}

	.card-title-aula-virtual {
		font-size: 2.5rem !important;
	}

	.nav-link {
		font-size: 2.5rem !important;
	}

	.icon {
		font-size: 5rem !important;
	}

	.svg-notificaciones {
		font-size: 4rem !important;
	}

	.title-perfiles {
		font-size: 5rem !important;
	}

	.swal2-html-container {
		font-size: 3rem !important;
	}

	.logo-empresarial {
		width: 30rem !important;
	}

	.p-colorpicker-preview {
		width: 4rem !important;
	}
	.notificacion-tour {
		height: 600px !important;
	}
	.notificacion {
		height: auto !important;
	}
	.icon-timeline {
		width: 4rem !important;
		height: 4rem !important;
	}

	.pill-checkbox-label {
		padding: 28px 28px !important;
	}

	.check {
		font-size: 2rem !important;
	}

	.css-19id26o {
		height: 95vh !important;
	}

	.css-1wvake5 {
		width: 35rem;
	}

	.sideitem {
		font-size: 10rem !important;
	}
	.welcome-text {
		font-size: 3rem !important;
	  }
	  .swal2-popup.swal2-toast .swal2-title {
		font-size: 1em !important;
	  }
}



@media (min-width: 2550px) {
	.img-login {
		height: 100% !important;
	}
	.welcome-text {
		font-size: 4rem !important;
	  }
	  .swal2-popup.swal2-toast .swal2-title {
		font-size: 1em !important;
	  }

	  .bs-popover-start{
		min-width: 40rem !important;
	  }

	  .bs-popover-end{
		min-width: 40rem !important;
	  }

	  .bs-popover-top{
		min-width: 40rem !important;
	  }

	  .bs-popover-bottom{
		min-width: 40rem !important;
	  }
	  .carousel .slider-wrapper{
		height: 50rem!important;
	  }
}

@media (min-width: 4000px) {
	h1 {
		font-size: 8.8rem !important;
	}
	h2 {
		font-size: 6.3rem !important;
	}
	h3 {
		font-size: 6rem !important;
	}
	h4 {
		font-size: 5.4rem !important;
	}
	h5 {
		font-size: 4.8rem !important;
	}
	h6 {
		font-size: 4rem !important;
	}

	b {
		font-size: 50px;
	}
	p {
		font-size: 50px;
	}
	li {
		font-size: 1.7rem;
	}

	.lordicon {
		width: 500px !important;
		height: 450px !important;
	}

	.lordicon-container {
		margin: 0 auto;
		width: 600px !important;
		height: 450px !important;
	}

	label {
		font-size: 2rem !important;
	}

	input {
		font-size: 2.7rem !important;
	}
	select {
		font-size: 2.7rem !important;
	}

	button {
		font-size: 3.5rem !important;
	}
	textarea {
		font-size: 2.7rem !important;
	}
	small {
		font-size: 2.7rem !important;
	}
	th {
		font-size: 2.7rem !important;
	}
	td {
		font-size: 2.7rem !important;
	}
	svg {
		font-size: 65px !important;
	}
	i {
		font-size: 3.5rem !important;
	}

	li {
		font-size: 2.7rem !important;
	}
	span {
		font-size: 2.7rem !important;
	}
	.select-form {
		font-size: 2.7rem !important;
	}
	.ellipsis {
		font-size: 4rem !important;
	}

	.hIWEKa {
		font-size: 46px !important;
		margin-bottom: 2rem !important;
	}

	.DSsoU {
		font-size: 40px !important;
		margin-bottom: 1rem !important;
	}

	.gKbhqU {
		font-size: 30px !important;
	}
	.fyrdjl {
		font-size: 2rem !important;
	}

	.titulo-dashboard {
		font-size: 5.5rem !important;
	}
	.separador-titulo {
		width: 250px !important;
		height: 10px !important;
	}

	.swal2-popup {
		width: 2500px !important;
	}

	.swal2-title {
		font-size: 4rem !important;
	}

	.swal2-html-container {
		font-size: 5rem !important;
	}

	.row-foro {
		height: 600px !important;
	}

	.nav-link {
		font-size: 3.5rem !important;
	}

	.icon {
		font-size: 10rem !important;
	}

	.avatar {
		width: 6rem !important;
		height: 6rem !important;
	}

	.svg-notificaciones {
		font-size: 6rem !important;
	}

	.title-perfiles {
		font-size: 7rem !important;
	}

	.p-colorpicker-preview {
		width: 7rem !important;
	}
	.alert {
		font-size: 2.7rem !important;
	}

	.icon-timeline {
		width: 8rem !important;
		height: 8rem !important;
	}

	.logo-empresarial {
		width: 30rem !important;
	}

	.notificacion-tour {
		height: 1000px !important;
	}
	.notificacion {
		height: auto !important;
	}
	.icon-timeline {
		width: 4rem !important;
		height: 4rem !important;
	}

	.pill-checkbox-label {
		padding: 50px 28px !important;
	}

	.check {
		size: 3rem !important;
	}
}

.element {
	/* position: relative !important;
	height: 90vh !important; */
	height: 100%;
	/* overflow: scroll !important; */
	/* margin-bottom: 100px !important; */
}

.caps-lock-on > div.arrow::before {
	border-top-color: #dc3545 !important;
}

.caps-lock-on > div.tooltip-inner {
	background-color: #dc3545 !important;
}

.fondo-ia {
	background-image: url(../images/texto.jpg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	height: 250px;
}

.fondo-generador{
	background: linear-gradient( rgba(0, 21, 42, 1), #013468);
}

.img-picture {
	font-size: 20rem;
	color: #fff;
	margin-top: 20px;
}

.fondo-imagenes-generadas {
	border-radius: 20px;
	/* background-color: rgb(37, 34, 34); */
	padding: 15px;
}

.__floater {
	z-index: 300000 !important;
}

.super-z-index {
	z-index: 9999999 !important;
}

.w-fit-content {
	width: fit-content !important;
}

.progreso-certificaciones-mos > *:nth-last-of-type(2):nth-last-of-type(2) {
	display: flex;
	flex-direction: column;
}

.text-white {
	color: #fff !important;
}

.btn-orange-gc {
	background-color: var(--orange-gc) !important;
}

.btn-orange-gc:hover {
	background-color: var(--yellow-gc) !important;
}

.detalle-compra {
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 0.5rem;
}

.detalle-compra-header > div.col {
	background-color: #00152a;
	border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	border-top-left-radius: 0.5rem;
	border-top-right-radius: 0.5rem;
	/* color: rgba(0, 0, 0, 0.5); */
	color: #fff;
	text-align: right;
	padding: calc(1rem - 10px) 1rem 1.5rem;
}

.detalle-compra-pricing {
	color: #fff;
	background-color: #00152a;
}

.detalle-compra-content > div.col,
.detalle-compra-pricing > div.col {
	padding: 2.5rem 1rem 0;
}

.detalle-compra-footer {
	background-color: #00152a;
	border-bottom-left-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	border-top: 1px solid rgba(255, 255, 255, 0.3);
	color: #fff;
	padding: calc(1rem - 1px) 1rem;
}

.compra-label {
	background-color: var(--green-success);
	left: 1rem;
	padding: 10px 10px 10px 20px;
	position: absolute;
	top: 4rem;
	z-index: 1;
}

.compra-label:before {
	content: " ";
	border-bottom: 10px;
	border-left: 15px solid transparent;
	border-right: 0 solid transparent;
	border-top: 10px solid var(--green-success);
	bottom: -10px;
	display: block;
	left: 0;
	position: absolute;
}

#btnAplicarCodigo {
	width: 25vw;
}

.strike {
	position: relative;
}

.strike:after {
	animation-duration: 1s;
	animation-fill-mode: forwards;
	animation-iteration-count: 1;
	animation-name: strike;
	animation-timing-function: linear;
	background: #ff9900;
	content: " ";
	height: 1px;
	left: 0;
	position: absolute;
	top: 50%;
	width: 100%;
}

@keyframes strike {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@media (max-width: 767px) {
	.compra-label {
		left: -3px;
		top: 2rem;
	}
}

@media (min-width: 768px) {
	.detalle-compra-header > div.col {
		padding: calc(1rem - 1px) 1rem;
	}

	.compra-label {
		left: -3px;
		top: 2rem;
	}
}

@media (min-width: 992px) {
	#btnAplicarCodigo {
		width: 15vw;
	}
}

.bg-blue-gc {
	background-color: #00152a !important;
}

.compra-prices {
	position: relative;
}

.hover-orange:hover {
	color: #ffcc00;
}

.form-accordion {
	border: 1px solid #495057;
}

.btn-next-accordion {
	height: calc(3.5rem + calc(var(--bs-border-width) * 2));

	.p-overlay-badge-nav {
		position: relative;
	}
}


.headerLV {
	background-image: url('../images/headerLV.png');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	height: 250px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 15px;
}


/* From Uiverse.io by gustavofusco */ 
.pencil {
	display: block;
	width: 10em;
	height: 10em;
  }
  
  .pencil__body1,
  .pencil__body2,
  .pencil__body3,
  .pencil__eraser,
  .pencil__eraser-skew,
  .pencil__point,
  .pencil__rotate,
  .pencil__stroke {
	animation-duration: 3s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
  }
  
  .pencil__body1,
  .pencil__body2,
  .pencil__body3 {
	transform: rotate(-90deg);
  }
  
  .pencil__body1 {
	animation-name: pencilBody1;
  }
  
  .pencil__body2 {
	animation-name: pencilBody2;
  }
  
  .pencil__body3 {
	animation-name: pencilBody3;
  }
  
  .pencil__eraser {
	animation-name: pencilEraser;
	transform: rotate(-90deg) translate(49px,0);
  }
  
  .pencil__eraser-skew {
	animation-name: pencilEraserSkew;
	animation-timing-function: ease-in-out;
  }
  
  .pencil__point {
	animation-name: pencilPoint;
	transform: rotate(-90deg) translate(49px,-30px);
  }
  
  .pencil__rotate {
	animation-name: pencilRotate;
  }
  
  .pencil__stroke {
	animation-name: pencilStroke;
	transform: translate(100px,100px) rotate(-113deg);
  }
  
  /* Animations */
  @keyframes pencilBody1 {
	from,
	  to {
	  stroke-dashoffset: 351.86;
	  transform: rotate(-90deg);
	}
  
	50% {
	  stroke-dashoffset: 150.8;
   /* 3/8 of diameter */
	  transform: rotate(-225deg);
	}
  }
  
  @keyframes pencilBody2 {
	from,
	  to {
	  stroke-dashoffset: 406.84;
	  transform: rotate(-90deg);
	}
  
	50% {
	  stroke-dashoffset: 174.36;
	  transform: rotate(-225deg);
	}
  }
  
  @keyframes pencilBody3 {
	from,
	  to {
	  stroke-dashoffset: 296.88;
	  transform: rotate(-90deg);
	}
  
	50% {
	  stroke-dashoffset: 127.23;
	  transform: rotate(-225deg);
	}
  }
  
  @keyframes pencilEraser {
	from,
	  to {
	  transform: rotate(-45deg) translate(49px,0);
	}
  
	50% {
	  transform: rotate(0deg) translate(49px,0);
	}
  }
  
  @keyframes pencilEraserSkew {
	from,
	  32.5%,
	  67.5%,
	  to {
	  transform: skewX(0);
	}
  
	35%,
	  65% {
	  transform: skewX(-4deg);
	}
  
	37.5%, 
	  62.5% {
	  transform: skewX(8deg);
	}
  
	40%,
	  45%,
	  50%,
	  55%,
	  60% {
	  transform: skewX(-15deg);
	}
  
	42.5%,
	  47.5%,
	  52.5%,
	  57.5% {
	  transform: skewX(15deg);
	}
  }
  
  @keyframes pencilPoint {
	from,
	  to {
	  transform: rotate(-90deg) translate(49px,-30px);
	}
  
	50% {
	  transform: rotate(-225deg) translate(49px,-30px);
	}
  }
  
  @keyframes pencilRotate {
	from {
	  transform: translate(100px,100px) rotate(0);
	}
  
	to {
	  transform: translate(100px,100px) rotate(720deg);
	}
  }
  
  @keyframes pencilStroke {
	from {
	  stroke-dashoffset: 439.82;
	  transform: translate(100px,100px) rotate(-113deg);
	}
  
	50% {
	  stroke-dashoffset: 164.93;
	  transform: translate(100px,100px) rotate(-113deg);
	}
  
	75%,
	  to {
	  stroke-dashoffset: 439.82;
	  transform: translate(100px,100px) rotate(112deg);
	}
  }

 